import React, { useEffect, useState, useRef } from 'react';

import {
    Button,
    Loader,
    Icon,
} from 'semantic-ui-react';
import Fuse from 'fuse.js';

import { Person, Company, getCompaniesPersons, useControlSocket } from '../utils/api';
import { openDoorsStaggered } from '../utils/door';
import CallModal from './CallModal';

import ringingUrl from 'url:../../static/ringing.mp3';

export interface StyleSheet {
    readonly [styleName: string]: {
        readonly [propertyName: string]: string | number;
    };
}

interface CallButtonProps {
    style?: object
};
const CallButton: React.FC<CallButtonProps> = ({style}) => (
    <div style={{...styles.callButton, ...style}}>
        <Icon name="call" size="big" />
    </div>
);

interface DirectCallButtonProps {
    title: string
    onPress?: () => void
    imageSrc: string
};
const DirectCallButton: React.FC<DirectCallButtonProps> = ({ onPress, imageSrc }) => {
    return (
        <div style={styles.directCallButtonContainer} onClick={onPress}>
            <img style={styles.directCallImage} src={imageSrc} />
            <CallButton />
        </div>
    );
};

interface DirectoryProps {
    onPersonSelected: (person: Person) => void
    persons: Person[]
    fuse: Fuse<Person>
};
const Directory: React.FC<DirectoryProps> = ({ fuse, onPersonSelected }) => {
    const [query, setQuery] = useState('');
    const filteredPersons = (query.length && fuse) ? fuse.search(query) : [];

    const onPersonClick = (person) => {
        onPersonSelected(person);
        setQuery('');
    }

    return (
        <div style={styles.directoryContainer}>
            <div style={styles.header}>Find a company or person:</div>
            <div style={styles.searchInputContainer}>
                <input style={styles.searchInput}
                    onChange={(event) => setQuery(event.target.value)}
                    value={query}
                />

                {query && (
                    <div style={styles.searchClearButton} onClick={() => setQuery('')}>
                        <Icon name="close" size="big" />
                    </div>
                )}
            </div>
            <div style={styles.peopleListContainer}>
                {filteredPersons.map((person, n) => (
                    <PersonItem key={`${n}`} person={person.item} onPress={() => onPersonClick(person.item)} />
                ))}
                {!filteredPersons.length && (
                    <div style={styles.searchHintText}>
                        Enter a name to start searching...
                    </div>
                )}
            </div>
        </div>
    );
};

interface PersonItemProps {
    person: Person
    onPress: () => void
};
const PersonItem: React.FC<PersonItemProps> = ({ person, onPress }) => {
    return (
        <div style={styles.personContainer} onClick={() => onPress()}>
            <div style={{...styles.header, ...styles.personName}}>{person.name}</div>
            <div>
                <CallButton style={styles.personCallButton} />
            </div>
        </div>
    );
}

const useFetchData: () => [Company[] | null, Person[] | null, Fuse<Person> | null] = () => {
    const [persons, setPersons] = useState<Person[] | null>(null);
    const [companies, setCompanies] = useState<Company[] | null>(null);
    const [fuse, setFuse] = useState<Fuse<Person> | null>(null);
    useEffect(() => {
        if (!persons && !companies) {
            getCompaniesPersons().then(([companies, persons]) => {
                setPersons(persons);
                setCompanies(companies);
                setFuse(new Fuse(persons, { keys: ['name'] }));
            });
        }
    }, [persons, companies]);

    return [companies, persons, fuse];
};

const Root: React.FC = () => {
    const [companies, persons, fuse] = useFetchData();
    const [callingPerson, setCallingPerson] = useState<Person | null>(null);
    const [openingDoor, setOpeningDoor] = useState(false);
    const [answeredIntercom, setAnsweredIntercom] = useState(false);
    const activeCallRef = useRef<string | null>(null);

    const [showDebug, setShowDebug] = useState(false);
    const debugCountRef = useRef(0);
    const debugIncrement = () => {
        debugCountRef.current++;
        if (debugCountRef.current >= 5) {
            debugCountRef.current = 0;
            setShowDebug((showDebug) => !showDebug);
        }
    }

    const ringingRef = useRef(new Audio(ringingUrl));
    ringingRef.current.loop = true;

    useEffect(() => {
        // to get the TS compiler off of our backs
        (window as any).native?.ready?.();
    }, []);



    // Reload every hour to prevent some hangs / crashes that seem to happen
    // occasionally.
    useEffect(() => {
        setTimeout(() => window.location.reload(), 3600 * 1000);
    }, []);

    const stopRinging = () => {
        ringingRef.current.pause();
        ringingRef.current.currentTime = 0;
    };

    const dismissModal = () => {
        stopRinging();
        activeCallRef.current = null;
        setCallingPerson(null);
        setOpeningDoor(false);
        setAnsweredIntercom(false);
    };

    const showCallModal = (person) => {
        setCallingPerson(person);
        setOpeningDoor(false);
        setAnsweredIntercom(false);

        ringingRef.current.play();
    };

    const onMessage = (message) => {
        const command = message?.command;
        if (!command) {
            return;
        }
        if (command === 'unlock') {
            stopRinging();

            openDoorsStaggered();
            setOpeningDoor(true);
            setTimeout(() => {
                dismissModal();
            }, 15000);
        }

        if (command === 'expire' && message?.call_id === activeCallRef.current) {
            dismissModal();
        }

        if (command === 'answered') {
            stopRinging();

            setAnsweredIntercom(true);
        }
    };
    useControlSocket(onMessage);

    if (!companies || !persons || !fuse) {
        return <Loader />
    }

    return (
        <div>
            <CallModal
                open={!!callingPerson}
                onClose={dismissModal}
                person={callingPerson}
                openingDoor={openingDoor}
                answeredIntercom={answeredIntercom}
                onStartCall={(callId) => { activeCallRef.current = callId; }}
            />
            <div>
                <div style={styles.header} onClick={debugIncrement}>
                    Intercom <span style={styles.headerSub}>Lizzy Ansinghstraat 163</span>
                </div>

                <div style={styles.directCallContainer}>
                    {companies.map((company) => (
                        <DirectCallButton
                            key={company.name}
                            title={company.name}
                            imageSrc={company.logoUrl}
                            onPress={() => showCallModal(company)}
                        />
                    ))}
                </div>
                {showDebug && <div style={styles.debugButtonContainer}>
                    {/* <Button negative onClick={() => openDoorsStaggered()} size="massive">Open</Button> */}
                    <Button negative onClick={() => location.reload()} size="massive">Reload</Button>
                </div>}


                <Directory persons={persons} fuse={fuse} onPersonSelected={(person) => showCallModal(person)} />
            </div>
        </div>
    );
}

const styles: StyleSheet = {
    header: {
        fontSize: 20,
        fontFamily: 'AvenirNext-Black',
        color: 'black',
        textAlign: 'center',
        padding: 20,
    },
    headerSub: {
        fontFamily: 'AvenirNext-Light',
    },
    directCallContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'center',
        margin: 10,
        marginTop: 5,
    },
    directCallButtonContainer: {
        flex: 1,
        marginLeft: 20,
        marginRight: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    directCallImage: {
        borderRadius: 20,
        width: '100%',
        maxHeight: 500,
    },
    callButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        borderRadius: '100%',
        marginTop: 15,
        width: 60,
        height: 60,
    },
    bigButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    debugButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 20,
    },
    directoryContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 20,
        marginLeft: 25,
        marginRight: 25,
    },
    peopleListContainer: {
        backgroundColor: '#F6F6F8',
        paddingLeft: 20,
        paddingRight: 20,
        marginLeft: 30,
        marginRight: 30,
        borderBottomRightRadius: 20,
        borderBottomLeftRadius: 20,
        height: 300,
        overflowY: 'scroll',
    },
    personContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: '1px solid white',
        marginLeft: -20,
        marginRight: -20,
    },
    personName: {
        flex: 1,
        textAlign: 'start',
    },
    personCallButton: {
        marginTop: 10,
        marginBottom: 10,
        marginRight: 10,
    },
    searchInputContainer: {
        borderRadius: 20,
        height: 50,
        borderColor: 'white',
        backgroundColor: 'white',
    },
    searchInput: {
        position: 'absolute',
        width: '100%',
        fontFamily: 'AvenirNext-Black',
        fontSize: 20,
        textAlign: 'center',
        border: 0,
        backgroundColor: 'rgba(0,0,0,0)',
        outline: 'none',
        marginTop: 14,
    },
    searchClearButton: {
        position: 'absolute',
        right: 35,
        marginTop: 13,
        color: 'black',
    },
    searchHintText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 24,
        fontFamily: 'AvenirNext-Light',
        color: '#666666',
        height: '100%'
    }
};

export default Root;