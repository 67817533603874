export const openDoor: (relayId: number, durationMs: number) => void = (relayId, duration) => {
    (window as any).native?.setRelay?.(relayId, true);
    setTimeout(() => {
        (window as any).native?.setRelay?.(relayId, false);
    }, duration);
}

export const openDoorsStaggered: () => void = () => {
    openDoor(2, 5000);
    setTimeout(() => {
        openDoor(1, 10000);
    }, 1000);
}
