import React, { useEffect, useRef, useState } from 'react';

import { Modal, Icon, } from 'semantic-ui-react';

import { cancelCall, Person, startCall } from '../utils/api';
import IntercomMedia from './IntercomMedia';
import { StyleSheet } from './Root';

interface CallModalProps {
    open: boolean
    onClose: () => void
    person: Person | null
    openingDoor: boolean
    answeredIntercom: boolean
    onStartCall: (callId: string) => void
    connect?: boolean
}
const CallModal: React.FC<CallModalProps> = ({ open, onClose, person, openingDoor, answeredIntercom, onStartCall, connect=true }) => {
    const [twilioToken, setTwilioToken] = useState<string | null>(null);
    const [callId, setCallId] = useState<string | null>(null);

    const onHangup = () => {
        if (callId) {
            cancelCall(callId);
        }
        onClose();
    };

    useEffect(() => {
        if (open && person && connect) {
            startCall(person).then(({callId, twilioToken}) => {
                onStartCall(callId);
                setTwilioToken(twilioToken);
                setCallId(callId);
            });
        } else {
            setTwilioToken(null);
            setCallId(null);
        }
    }, [open, person]);

    const [blink, setBlink] = useState(true);
    const blinkTimerRef = useRef<number | null>(null);
    useEffect(() => {
        if (openingDoor) {
            blinkTimerRef.current = setInterval(() => setBlink((blink) => !blink), 750) as unknown as number;
            return () => {
                blinkTimerRef.current && clearInterval(blinkTimerRef.current)
            };
        }
    });

    return (
        <>
            {!!connect && <IntercomMedia token={twilioToken} publishing={open} />}
            <Modal
                open={open}
                style={{...styles.modal, ...(openingDoor && blink ? styles.openingModal : null)}}
                dimmer="blurring"
            >
                <div style={styles.contentContainer}>
                    <div style={styles.callingText}>{answeredIntercom ? 'Connected to' : 'Calling'}:</div>
                    <div style={styles.nameText}>{person?.name ?? ''}</div>
                    <div style={styles.directionsText}>{person?.directions ?? ''}</div>
                    {!answeredIntercom && (
                        <div style={styles.statusText} onClick={onHangup}>
                            Ringing...
                        </div>
                    )}
                    {answeredIntercom && !openingDoor && (
                        <div style={styles.hangupButton} onClick={onHangup}>
                            <Icon name="phone" size="big" inverted />
                        </div>
                    )}
                    {openingDoor && (
                        <div style={{...styles.statusText, ...styles.openingText}} onClick={onHangup}>
                            {blink && <span>DOOR IS OPEN</span>}
                        </div>
                    )}
                    <div style={styles.closeButton} onClick={onHangup}>
                        <Icon name="close" size="big" />
                    </div>
                </div>
            </Modal>
        </>
    );
};

const styles: StyleSheet = {
    modal: {
        borderRadius: 20,
        padding: 10,
        border: '10px solid rgba(0,0,0,0)'
    },
    openingModal: {
        border: '10px solid red'
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    callingText: {
        marginTop: 70,
        fontFamily: 'AvenirNext-Light',
        fontSize: 24,
    },
    nameText: {
        marginTop: 10,
        marginBottom: 10,
        fontFamily: 'AvenirNext-Black',
        fontSize: 30,
    },
    directionsText: {
        fontFamily: 'AvenirNext-Light',
        fontSize: 20,
        marginBottom: 50
    },
    hangupButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100%',
        width: 70,
        height: 70,
        backgroundColor: '#C52C22',
        transform: 'rotate(225deg)',
        marginBottom: 20,
    },
    statusText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100%',
        fontFamily: 'AvenirNext-Black',
        fontSize: 24,
        height: 70,
        marginBottom: 20,
    },
    openingText: {
        color: 'red',
        fontSize: 30,
    },
    closeButton: {
        position: 'absolute',
        right: 20,
        top: 20,
    }
};

export default CallModal;