import React, { useEffect, useRef } from 'react';

import * as Twilio from 'twilio-video';

import useTwilio from '../utils/useTwilio';
import useTwilioTracks from '../utils/useTwilioTracks';

interface IntercomMediaProps {
    publishing: boolean
    token: string | null
};
const IntercomMedia: React.FC<IntercomMediaProps> = ({publishing, token}) => {
    const { viewers } = useTwilio(!!token && publishing, token ?? '', {audio: true, video: true, name: 'intercom'});

    return <>
        {viewers.map((viewer) => <IntercomViewer key={viewer.sid} viewer={viewer as Twilio.RemoteParticipant} />)}
    </>;
};

interface IntercomViewerProps {
    viewer: Twilio.RemoteParticipant
};
const IntercomViewer: React.FC<IntercomViewerProps> = ({viewer}) => {
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const { audioTrack } = useTwilioTracks(viewer);

    useEffect(() => {
        if (audioTrack && audioRef.current) {
            audioTrack.attach(audioRef.current);

            return () => {
                audioRef.current && audioTrack?.detach(audioRef.current);
            };
        }
    }, [audioTrack]);

    return <div>
        <audio ref={audioRef} autoPlay />
    </div>;
}

export default IntercomMedia;